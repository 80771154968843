<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddManufacturer
      :StoreObj="StoreObj"
      :addManufacturerDialog="addManufacturerDialog"
      @clicked="addManufacturerDialogEmit"
    />
    <DeleteManufacturer
      :dialogDeleteManufacturer="dialogDeleteManufacturer"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteManufacturerEmit"
    />
    <v-card class="ma-1" flat elevation="0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">Manufacturers</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              outlined
              dense
              class="
                FontSize
                mt-9
                maxWidthSmall
                field_label_size field_height
                mr-2
              "
              label="Status"
              v-model="organization_status"
              :items="StatusItems"
            ></v-select>
            <v-text-field
              dense
              outlined
              class="FontSize mt-9 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="
                    (addManufacturerDialog = true), (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2 mt-3 primary white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Manufacturer</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :no-data-text="noDataText"
          :items="GetAllManufacturerList"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.organization_name`]="{ item }">
            <div class="FontSize">{{ item.organization_name }}</div>
          </template>
          <template v-slot:[`item.country_name`]="{ item }">
            <div class="FontSize">{{ item.country_name }}</div>
          </template>
          <template v-slot:[`item.organization_ticket_prefix`]="{ item }">
            <div class="FontSize">{{ item.organization_ticket_prefix }}</div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item, 'EDIT')"
                  color="primary"
                  v-if="item.organization_status == 'ACTIVE'"
                  small
                  v-on="on"
                  class="mr-2"
                >
                  mdi-pencil-plus
                </v-icon>
              </template>
              <span class="white--text">Update Manufacturer Details</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item, 'DELETE')"
                  :color="
                    item.organization_status == 'ACTIVE' ? 'red' : 'green'
                  "
                  small
                  v-on="on"
                  class="red--text"
                >
                  {{
                    item.organization_status == "ACTIVE"
                      ? "mdi-close-thick"
                      : "mdi-check"
                  }}
                </v-icon>
              </template>
              <span class="white--text">
                {{
                  item.organization_status == "ACTIVE"
                    ? "De-Activate this Manufacturer ?"
                    : "Activate this Manufacturer ?"
                }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllManufacturer } from "@/mixins/GetAllManufacturer.js";
import Overlay from "@/components/Extras/Overlay.vue";
import AddManufacturer from "@/components/Manufacturer/Dialogs/AddManufacturer.vue";
import DeleteManufacturer from "@/components/Manufacturer/Dialogs/DeleteManufacturer.vue";
export default {
  components: {
    Overlay,
    AddManufacturer,
    DeleteManufacturer,
  },
  mixins: [GetAllManufacturer],
  data: () => ({
    TableHeight: 0,
    search: "",
    organization_status: "ACTIVE",
    overlay: false,
    addManufacturerDialog: false,
    dialogDeleteManufacturer: false,
    GetAllManufacturerList: [],
    StatusItems: ["ACTIVE", "INACTIVE"],
    headers: [
      { text: "Manufacturer Name", value: "organization_name" },
      { text: "Country ", value: "country_name" },
      { text: "Ticket Prefix", value: "organization_ticket_prefix" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
  }),
  watch: {
    organization_status(val) {
      this.GetAllBMSMenufacturerMethod(val);
    },
  },
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.GetAllBMSMenufacturerMethod(this.organization_status);
  },
  methods: {
    dialogDeleteManufacturerEmit(Toggle) {
      this.dialogDeleteManufacturer = false;
      if (Toggle == 2) {
        this.GetAllBMSMenufacturerMethod(this.organization_status);
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "EDIT") {
        this.addManufacturerDialog = true;
      } else if (action == "DELETE") {
        this.dialogDeleteManufacturer = true;
      }
    },
    addManufacturerDialogEmit(Toggle) {
      this.addManufacturerDialog = false;
      if (Toggle == 2) {
        this.GetAllBMSMenufacturerMethod(this.organization_status);
      }
    },
  },
};
</script>

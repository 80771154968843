<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="addManufacturerDialog">
      <v-toolbar class="primary elevation-0" dense dark>
        <v-toolbar-title class="ml-2 white--text"
          >Add Manufacturer</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          large
          class="mr-n4"
          dark
          text
          @click="addManufacturerDialogEmit((Toggle = 1))"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-4 pb-0">
          <v-form ref="form">
            <v-text-field
              outlined
              dense
              label="Manufacturer Name *"
              class="FontSize field_label_size field_height"
              :rules="[(v) => !!v || 'Required']"
              v-model="organization_name"
            ></v-text-field>
            <v-select
              label="Country Name *"
              v-model="country_name"
              :rules="[(v) => !!v || 'Required']"
              :items="CountryList"
              item-value="name"
              item-text="name"
              dense
              :readonly="StoreObj.action == 'EDIT'"
              outlined
              class="FontSize field_label_size field_height"
            >
            </v-select>
            <v-text-field
              outlined
              dense
              label="Ticket Prefix *"
              class="FontSize field_label_size field_height"
              hint="Ticket Prefix must be 3 Characters"
              persistent-hint
              :rules="[(v) => !!v || 'Required']"
              v-model="org_ticket"
              @keyup="callmethodUppercase(org_ticket)"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateMethod()"
            class="mr-2 secondary white--text"
            ><span v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { AddManufacturer, Update_Organization } from "@/graphql/mutations.js";
import CountryList from "@/mixins/CountryList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  props: {
    StoreObj: Object,
    addManufacturerDialog: Boolean,
  },
  data: () => ({
    Toggle: 0,
    org_ticket: "",
    country_name: "",
    organization_name: "",
    isLoading: false,
    CountryList: [],
    SnackBarComponent: {},
  }),
  watch: {
    addManufacturerDialog(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.organization_name = this.StoreObj.organization_name;
          (this.country_name = this.StoreObj.country_name),
            (this.org_ticket = this.StoreObj.organization_ticket_prefix);
        }
      }
    },
  },
  mounted() {
    this.CountryList = CountryList;
  },
  methods: {
    callmethodUppercase(org_ticket) {
      this.org_ticket = org_ticket.toUpperCase();
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          this.addManufacturer();
        } else {
          this.updateManufacturer();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all required details",
        };
      }
    },
    async updateManufacturer() {
      this.isLoading = true;
      try {
        var inputParams = {
          organization_id: this.StoreObj.organization_id,
          organization_name: this.organization_name,
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          organization_ticket_prefix: this.org_ticket.toUpperCase(),
        };
        let result = await API.graphql(
          graphqlOperation(Update_Organization, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.Update_Organization);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addManufacturerDialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async addManufacturer() {
      this.isLoading = true;
      try {
        var inputParams = {
          organization_name: this.organization_name,
          country_name: this.country_name,
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          organization_ticket_prefix: this.org_ticket.toUpperCase(),
        };
        let result = await API.graphql(
          graphqlOperation(AddManufacturer, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.AddManufacturer);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.status_message,
          };
          this.addManufacturerDialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    addManufacturerDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
